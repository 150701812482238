/* pdf-viewer.css */

.Avatar__menu {
    background: #F8F8FF; /* Same background color as Tip menu */
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(37, 40, 43, 0.2);
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .Avatar__menu-option {
    background-color: #8DA47E; /* Matching Tip button color */
    color: #F8F8FF; /* White text */
    padding: 5px 10px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .Avatar__menu-option:hover {
    background-color: #AAD9CD; /* Hover color matching Tip */
  }
  
  /* Optional: Adjust positioning to keep the menu on screen */
  .absolute {
    position: absolute;
  }
  

  .Annotation__box {
    margin: 20px 0; /* Vertical spacing */
    padding: 15px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 450px; /* Fixed width for the box */
    position: absolute; /* Ensures it can float */
    z-index: 1000; /* Make sure it's on top */
  }
  
  .Annotation__box .flex {
    display: flex;
    align-items: center;
  }
  
  .Annotation__box blockquote {
    margin: 10px 0;
    padding-left: 15px;
    border-left: 4px solid #888;
    font-style: italic;
    color: #555;
  }
  
  .Annotation__reply-button {
    background-color: #8DA47E;
    color: white;
    border: none;
    padding: 6px 12px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .Annotation__reply-button:hover {
    background-color: #0056b3;
  }
  
  img.rounded-full {
    display: inline-block;
    background-color: #ccc;
    border-radius: 50%;
  }
  
.Tip__compact {
    cursor: pointer;
    background-color: #3d464d;
    border: 1px solid rgba(255, 255, 255, 0.25);
    color: white;
    padding: 5px 10px;
    border-radius: 3px;
  }
  
  .Tip__card {
    padding: 10px;
    background: #fff;
    background-clip: padding-box;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(37, 40, 43, 0.2);
  }
  
  .Tip__card textarea {
    font-size: 16px;
    width: 400px;
    height: 140px;
  }
  
  .Tip__card input[type="submit"] {
    margin-top: 5px;
    font-size: large;
  }

  .Tip__menu {
    background: #F8F8FF;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(37, 40, 43, 0.2);
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .Tip__menu-option {
    xbackground-color: #3d464d;
    background-color: #8DA47E;
    color: #F8F8FF;
    padding: 5px 10px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .Tip__menu-option:hover {
    xbackground-color: #555e65;
    background-color: #AAD9CD;
  }  

  .max-w-72 {
    max-width: 72rem; /* default max width */
  }
  
  /* smaller max width for small screens */
  @media (max-width: 768px) {
    .max-w-72 {
      max-width: 40rem; /* smaller max width for screens <= 768px */
    }
  }
  
  /* even smaller max width for very small screens */
  @media (max-width: 480px) {
    .max-w-72 {
      max-width: 24rem; /* even smaller max width for screens <= 480px */
    }
  }

  .max-w-64 {
    max-width: 70rem; /* default max width */
  }
  
  /* smaller max width for small screens */
  @media (max-width: 768px) {
    .max-w-64 {
      max-width: 38rem; /* smaller max width for screens <= 768px */
    }
  }
  
  /* even smaller max width for very small screens */
  @media (max-width: 480px) {
    .max-w-64 {
      max-width: 22rem; /* even smaller max width for screens <= 480px */
    }
  }
@tailwind base;
@tailwind components;
@tailwind utilities;

@import './components/old-profile.css';

html, body, #root {
    height: 100%;
    margin: 0;
    padding: 0;
}

.flex-1 {
    flex: 1 1 0%;
}

.text-color1 { color: #E9BBB5; } /* Indian Red */
.text-color2 { color: #E7CBA9; } /* Burly Wood */
.text-color3 { color: #AAD9CD; } /* Light Steel Blue */
.text-color4 { color: #E8D595; } /* Light Khaki */
.text-color5 { color: #8DA47E; } /* Dark Sea Green */
.text-color6 { color: #e5e7eb; } /* Light gray */
.text-color7 { color: #DCE9E6; } /* Azure */
.text-color8 { color:#F8F8FF; } /* Ghost white */
.text-color9 { color:#E0FFFF; } /* Light Cyan */
.text-color10 { color:#FFFFF0; } /* Ivory */
.text-color11 { color:#2F4F4F; } /* Dark Slate Gray */
.text-color12 { color:#00008B; } /* Dark Blue */
.text-color13 { color:#000080; } /* Navy */
.text-color14 { color:#36454F; } /* Charcoal */
.text-color15 { color:#E0E0E0; } /* Gainsboro Light */
.text-color16 { color:#C6C6C6; } /* Gainsboro Dark */
.bg-color1 { background-color: #302321; } /* Indian Red */
.bg-color2 { background-color: #E7CBA9; } /* Burly Wood */
.bg-color3 { background-color: #AAD9CD; } /* Light Steel Blue */
.bg-color4 { background-color: #E8D595; } /* Light Khaki */
.bg-color5 { background-color: #8DA47E; } /* Dark Sea Green */
.bg-color6 { background-color: #e5e7eb; } /* Light gray */
.bg-color7 { background-color: #DCE9E6; } /* Azure */
.bg-color8 { background-color:#F8F8FF; } /* Ghost white */
.bg-color9 { background-color:#E0FFFF; } /* Light Cyan */
.bg-color10 { background-color:#FFFFF0; } /* Ivory */
.bg-color11 { background-color:#2F4F4F; } /* Dark Slate Gray */
.bg-color12 { background-color:#00008B; } /* Dark Blue */
.bg-color13 { background-color:#000080; } /* Navy */
.bg-color14 { background-color:#36454F; } /* Charcoal */
.bg-color15 { background-color:#E0E0E0; } /* Gainsboro Light */
.bg-color16 { background-color:#C6C6C6; } /* Gainsboro Dark */

.hover\:text-color3:hover {
    color: #AAD9CD; /* Light Steel Blue */
  }
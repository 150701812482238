/* profile.css */
.list-item {
    margin: 0; /* Remove any default margin */
    padding: 0; /* Remove any default padding */
    line-height: 1.5; /* Adjust line-height to desired value */
}

/* Skills & Expertise */
.skills-expertise-header {
    @apply text-2xl font-semibold text-[#00008B]; /* Dark Blue */
  }
  .skills-expertise-item {
    @apply text-sm text-[#00008B]; /* Dark Blue */
  }
  .skills-expertise-bullet {
    @apply list-disc list-inside text-[#00008B]; /* Dark Blue */
  }
  
  /* Work & Achievements */
  .work-achievements-header {
    @apply text-2xl font-semibold text-[#000080]; /* Navy */
  }
  .work-achievements-item {
    @apply text-xl text-[#36454F]; /* Charcoal */
  }
  .work-achievements-bullet {
    @apply list-disc list-inside text-[#E9BBB5]; /* Indian Red */
  }
  
  /* Goals & Aspirations */
  .goals-aspirations-header {
    @apply text-2xl font-semibold text-[#E8D595]; /* Light Khaki */
  }
  .goals-aspirations-item {
    @apply text-sm text-[#E8D595]; /* Light Khaki */
  }
  .goals-aspirations-bullet {
    @apply list-disc list-inside text-[#E7CBA9]; /* Burly Wood */
  }
  
  /* Education & Experience */
  .education-experience-header {
    @apply text-2xl font-semibold text-[#E7CBA9]; /* Burly Wood */
  }
  .education-experience-item {
    @apply text-sm text-[#8DA47E]; /* Dark Sea Green */
  }
  .education-experience-bullet {
    @apply list-disc list-inside text-[#AAD9CD]; /* Light Steel Blue */
  }
  
  /* Online Presence */
  .online-presence-header {
    @apply text-2xl font-semibold text-[#AAD9CD]; /* Light Steel Blue */
  }
  .online-presence-item {
    @apply text-sm text-[#2F4F4F]; /* Dark Slate Gray */
  }
  .online-presence-bullet {
    @apply list-disc list-inside text-[#000080]; /* Navy */
  }
  
  /* Personal Information */
  .personal-information-header {
    @apply text-2xl font-semibold text-[#2F4F4F]; /* Dark Slate Gray */
  }
  .personal-information-item {
    @apply text-sm text-[#8DA47E]; /* Dark Sea Green */
  }
  .personal-information-bullet {
    @apply list-disc list-inside text-[#DCE9E6]; /* Azure */
  }
  
  /* Artistic Profile */
  .artistic-profile-header {
    @apply text-2xl font-semibold text-[#E9BBB5]; /* Indian Red */
  }
  .artistic-profile-item {
    @apply text-sm text-[#E9BBB5]; /* Indian Red */
  }
  .artistic-profile-bullet {
    @apply list-disc list-inside text-[#36454F]; /* Charcoal */
  }
  
  /* Personal & Professional Brand */
  .personal-brand-header {
    @apply text-2xl font-semibold text-[#8DA47E]; /* Dark Sea Green */
  }
  .personal-brand-item {
    @apply text-sm text-[#8DA47E]; /* Dark Sea Green */
  }
  .personal-brand-bullet {
    @apply list-disc list-inside text-[#000080]; /* Navy */
  }
  
  /* Personal & Professional Connections */
  .personal-connections-header {
    @apply text-2xl font-semibold text-[#E0FFFF]; /* Light Cyan */
  }
  .personal-connections-item {
    @apply text-sm text-[#00008B]; /* Dark Blue */
  }
  .personal-connections-bullet {
    @apply list-disc list-inside text-[#AAD9CD]; /* Light Steel Blue */
  }
  
  /* Personal & Professional Interests */
  .personal-interests-header {
    @apply text-2xl font-semibold text-[#e5e7eb]; /* Light Gray */
  }
  .personal-interests-item {
    @apply text-sm text-[#2F4F4F]; /* Dark Slate Gray */
  }
  .personal-interests-bullet {
    @apply list-disc list-inside text-[#36454F]; /* Charcoal */
  }
  
  /* Personal Development */
  .personal-development-header {
    @apply text-2xl font-semibold text-[#DCE9E6]; /* Azure */
  }
  .personal-development-item {
    @apply text-sm text-[#DCE9E6]; /* Azure */
  }
  .personal-development-bullet {
    @apply list-disc list-inside text-[#8DA47E]; /* Dark Sea Green */
  }
  